/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridDTO, SalesRateResultDTO } from '../model/dto-module';
import BaseService from './BaseService';

export default class SalesRateService extends BaseService {

    private static _instance: SalesRateService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }
    

    async salesRateByHour({limit = 100, offset=0, intervalHours, sort, productCode, productDescription, warehouseCode}: any): Promise<GridDTO<SalesRateResultDTO>> {
        try {

        let endpoint = `/sales-rate/hours?limit=${limit}&offset=${offset}`;

        if(intervalHours) {
            endpoint = `${endpoint}&intervalHours=${intervalHours}`;
        }
        if(sort) {
            endpoint = `${endpoint}&sort=${sort}`;
        }

        if(productCode) {
            endpoint = `${endpoint}&productCode=${productCode}`;
        }

        if(warehouseCode) {
            endpoint = `${endpoint}&warehouseCode=${warehouseCode}`;
        }

        if(productDescription) {
            endpoint = `${endpoint}&productDescription=${encodeURIComponent(productDescription)}`;
        }

            
        const response = await this.client({
            url: endpoint,
            method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            // this.handleError(err); Se comenta para no tener mensajes de error en la pantalla 
            throw null;
        } 
    }

    async salesRateByDay({limit = 100, offset=0, intervalDays, sort, productCode, productDescription, warehouseCode}: any) {
        try {
        let endpoint = `/sales-rate/days?limit=${limit}&offset=${offset}`;

        if(intervalDays) {
            endpoint = `${endpoint}&intervalDays=${intervalDays}`;
        }
        if(sort) {
            endpoint = `${endpoint}&sort=${sort}`;
        }

        if(productCode) {
            endpoint = `${endpoint}&productCode=${productCode}`;
        }

        if(warehouseCode) {
            endpoint = `${endpoint}&warehouseCode=${warehouseCode}`;
        }

        if(productDescription) {
            endpoint = `${endpoint}&productDescription=${encodeURIComponent(productDescription)}`;
        }

        const response = await this.client({
            url: endpoint,
            method: 'GET'
            });
            console.warn(response);
            return response.data.data;

        } catch (err: any) {
            // this.handleError(err);
            return null;
        }
    }

}